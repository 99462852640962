<template>

  <BlockUI :blocked="loading > 0">

    <div class="grid dashboard">

      <div class="col-12 lg:col-4">

        <div class="card">

          <Accordion :multiple="true" v-model:activeIndex="expanded" class="w-full">
            <AccordionTab v-for="(ps, k) in pedidos" :key="k">
              <template #header>
                <i :class="(k == 'Espera') ? 'pi pi-inbox' : (k == 'Preparando' ? 'pi pi-upload' : 'pi pi-send')"></i>
                <span class="ml-2">{{ (k === 'Espera') ? 'Aguardando Aceite' : k }}</span>
                <div class="text-right" style="margin-left: auto; order: 2;">
                  {{ Object.values(ps).length }} pedidos
                </div>
              </template>

              <div class="grid" v-if="Object.values(ps).length == 0">
                <div class="col-12">
                  Nenhum pedido encontrado.
                </div>
              </div>

              <ul id="pedidos" class="p-0 m-0 list-none" v-else>
                <li v-for="p of ps" :key="p.id" class="pb-2 mb-3 border-bottom-1 border-gray-400">
                  <div class="grid pedido" @click="pedido_mostrar(p)">
                    <div class="col-12 md:col-4">
                      <strong>
                        #{{ p.numero }}
                      </strong>
                    </div>
                    <div class="col-12 md:col-5 text-center">
                      {{ (typeof p.statusEntrega != "undefined") ? p.statusEntrega.status : '' }}
                    </div>
                    <div class="col-12 md:col-3 text-right">
                      {{ p.hora }}
                    </div>
                    <div class="col-12">
                      <strong>
                        <i class="pi pi-user"></i> {{ p.usuario.nome }}
                      </strong><br>
                      <strong v-if="typeof p.statusEntrega != 'undefined' && p.statusEntrega.entregador != null">
                        <i class="pi pi-map"></i> {{ p.statusEntrega.entregador.nome }}
                      </strong>
                    </div>
                  </div>
                </li>
              </ul>

            </AccordionTab>
          </Accordion>

        </div>

      </div>

      <div class="col-12 lg:col-8">
        <div class="card mb-0">
          <GoogleMap ref="mapa" api-key="AIzaSyC-mah_TjzO0fSC1X5AgUgj02oSKLU-tZc"
            style="width: 100%; height: calc(100vh - 181px);" :center="mapCenter" :zoom="14">
            <Marker v-for="(m, index) in mapMarkers" :key="index" :options="m" />
          </GoogleMap>
        </div>
      </div>

    </div>

    <Dialog v-model:visible="pedido_edit_visible" style="width: 600px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-shopping-cart text-2xl"></i> <span class="text-2xl font-semibold">Pedido</span>
        </div>
      </template>

      <PedidoView :pedido="pedido_edit" />

      <template #footer>
        <Button label="Imprimir" icon="pi pi-print" class="p-button-text"
          @click="pedido_imprimir(pedido_edit, $event)" />
        <Button label="Recusar" icon="pi pi-times" class="p-button-danger" v-if="pedido_edit_acoes"
          @click="pedido_recusar(pedido_edit, $event)" />
        <Button label="Aceitar" icon="pi pi-check" class="p-button-success" v-if="pedido_edit_acoes"
          @click="pedido_aceitar(pedido_edit, $event)" autofocus />
        <Button label="Pedido Pronto" icon="pi pi-check" class="p-button-info" v-if="pedido_edit_acoes_pronto"
          @click="pedido_pronto(pedido_edit, $event)" />
        <Button label="Pedido Entregue" icon="pi pi-check" class="p-button-success" v-if="pedido_edit_acoes_entregue"
          @click="pedido_entregar(pedido_edit, $event)" />
      </template>

    </Dialog>

  </BlockUI>

  <div class="center-spinner" v-if="loading > 0">
    <ProgressSpinner />
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

import moment from 'moment-timezone';
import 'firebase/database';
import { GoogleMap, Marker } from 'vue3-google-map';

import PedidoView from "./pedidos/PedidoView.vue";
import { v4 as uuidv4 } from "uuid";


export default {
  data() {
    return {

      loading: 0,

      pedido_edit_visible: false,
      pedido_edit_acoes: false,
      pedido_edit_acoes_pronto: false,
      pedido_edit_acoes_entregue: false,
      pedido_edit: null,
      expanded: [],

      loja: null,
      pedidos: {},

      map: null,
      mapCenter: { lat: -16.713681718591975, lng: -49.26569591634313 },
      mapMarkers: {},

      statusPedidos: [
        "Espera",
        "Preparando",
        "Pronto",
        "Entregando",
      ]

    };
  },

  created() {

  },

  mounted() {
    if (this.loja != null) {
      this.clearData();
      this.clearMap();
      this.initObservers();
    }

  },

  watch: {

    '$auth.usuario.loja': {
      handler: function (val) {
        this.loja = this.$utils.getStdObject(val);
      },
      deep: true,
      immediate: true
    },

    loja: function (val) {

      if (val == null)
        return;

      this.clearData();
      this.clearMap();
      this.initObservers();
    },

    pedidos: {
      handler: function (val) {

        if (typeof val != "object" || val == null)
          return;

        this.expanded = [];
        for (let i = 0; i < this.statusPedidos.length; i++) {
          const status = this.statusPedidos[i];

          if (typeof this.pedidos[status] != "undefined" && this.pedidos[status] != null) {
            if (Object.values(this.pedidos[status]).length > 0) {
              this.expanded.push(i);
            }
          }
        }

        if (typeof this.pedidos["Espera"] != "undefined" && this.pedidos["Espera"] != null) {
          if (Object.values(this.pedidos["Espera"]).length > 0) {
            this.$root.alert = true;
          } else {
            this.$root.alert = false;
          }
        } else {
          this.$root.alert = false;
        }

      },
      deep: true,
      immediate: true
    },

  },

  methods: {


    initObservers() {

      if (this.loja == null) return;
      const self = this;

      this.clearData();


      this.get_pedidos();

      this.$rt.on("Pedido.insert", (dados) => {

        dados = dados.data;
        if (dados.loja.id != self.loja.id) return;

        console.log("chamou Insert", dados)
        if ((typeof dados.pagamentoReservado == "boolean" && dados.pagamentoReservado) || (typeof dados.pagamentoEfetivado == "boolean" && dados.pagamentoEfetivado)) {

          const prevStatus = self.getStatusOfPedido(dados.id);

          if (prevStatus != "" && prevStatus != dados.status) {
            delete self.pedidos[prevStatus][dados.id];
          }

          self.pedidos[dados.status][dados.id] = dados;

        }

      });

      this.$rt.on("Pedido.update", (dados) => {
        console.log("update")
        dados = dados.data.data;
        if (dados.loja.id != self.loja.id) return;


        if ((typeof dados.pagamentoReservado == "boolean" && dados.pagamentoReservado) || (typeof dados.pagamentoEfetivado == "boolean" && dados.pagamentoEfetivado)) {
          console.log("update 2")
          const prevStatus = self.getStatusOfPedido(dados.id);

          if (prevStatus != "" && prevStatus != dados.status) {
            delete self.pedidos[prevStatus][dados.id];
          }

          if (typeof self.pedidos[dados.status] != "undefined") {
            self.pedidos[dados.status][dados.id] = dados;
          }


          if (dados.status == "Espera" || dados.status == "Finalizado") return;
          console.log("update 3")
          this.$api.get('/corridas/' + dados.entrega.corrida).then(response => {
            if (response.data.success) {
              const corrida = response.data.data;
              console.log("update 4")
              if (corrida.status == "Iniciada") {
                delete self.pedidos[dados.status][dados.id];
                dados.status = "Entregando";
                self.pedidos[dados.status][dados.id] = dados;
              }
              console.log("update 5")
              if (typeof this.pedidos[dados.status][dados.id] == "undefined") return;
              console.log("update 6")
              this.pedidos[dados.status][dados.id].statusEntrega = {
                status: corrida.status,
                entregador: corrida.entregador,
                data: moment().tz("America/Sao_Paulo").toDate()
              };

            }
          });

        }

      });



    },
    clearData() {
      for (const s of this.statusPedidos) {
        this.pedidos[s] = {};
        this.expanded[s] = false;
      }
    },

    clearMap() {

      if (this.loja == null)
        return;

      if (this.$root.distribuidor == null) {

        this.mapCenter = {
          "lat": this.loja.lat,
          "lng": this.loja.lng
        };

      } else {

        this.mapCenter = this.$root.distribuidor.gps;

      }

      this.mapMarkers = {
        "loja": {
          "title": this.loja.nome + (this.$root.distribuidor != null ? " - " + this.$root.distribuidor.nome : ""),
          "position": this.mapCenter,
          "icon": "/icos/origemcorrida.png"
        }
      };

    },


    async get_pedidos() {
      if (this.loja == null) return;

      const ret = await this.$api.get('/pedidos?tipo=Pedido&status=!Recusado%26!Finalizado&with=usuario&loja.id=' + this.loja.id);

      for (let dados of ret.data.data) {

        if ((typeof dados.pagamentoReservado == "boolean" && dados.pagamentoReservado) || (typeof dados.pagamentoEfetivado == "boolean" && dados.pagamentoEfetivado)) {
          if (dados.status != "Espera") {

            const ret = await this.$api.get('/corridas/' + dados.entrega.corrida);

            if (ret.data.success) {
              const corrida = ret.data.data;
              dados.statusEntrega = {
                status: corrida.status,
                entregador: corrida.entregador,
                data: moment().tz("America/Sao_Paulo").toDate()
              };
              if (corrida.status == "Iniciada") {

                dados.status = "Entregando";

              }
            }
          }
          this.pedidos[dados.status][dados.id] = dados;
          this.monitorar(dados);
        }
      }
    },

    getStatusOfPedido(pedido_id) {
      if (typeof pedido_id == "string" && pedido_id != "") {
        for (const s of this.statusPedidos) {
          if (typeof this.pedidos[s][pedido_id] != "undefined")
            return s;
        }
      }
      return "";
    },

    async pedido_mostrar(pedido) {
      this.pedido_edit = pedido;
      this.pedido_edit_acoes = (pedido.status == "Espera");
      this.pedido_edit_acoes_pronto = (pedido.status == "Preparando");
      this.pedido_edit_acoes_entregue = (pedido.status == "Pronto");

      const ret = await this.$api.get("/pessoas/" + pedido.usuario.registro.id);
      if (!ret.data.success) {
        return;
      }
      pedido.usuario.registro = ret.data.data;
      this.pedido_edit_visible = true;
      //console.log(this.pedido_edit);

      this.acompanhar_entrega(pedido);
    },

    async pedido_aceitar(_pedido) {

      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function (message, error, context) {

        console.log("ERRO ACEITAR PEDIDO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Preparando";
      pedido.dataAceite = moment().tz("America/Sao_Paulo").toDate();

      /* #region ChamandoEntregadorMega */

      if (typeof pedido.tipoEntrega != "undefined" /*&& pedido.tipoEntrega.id.substr(0, 6) == "vipapp"*/) {

        console.log(pedido.numero)
        const entrega = {
          id: uuidv4(),
          cliente: pedido.usuario.registro,
          idExterno: String(pedido.numero),
          origem: "ecommerce",
          dataInicio: moment().tz("America/Sao_Paulo").toDate(),
          descricaoProduto: Object.values(pedido.listaProdutos).map(p => p.nome).join(", "),
          lat: pedido.usuario.lat,
          lng: pedido.usuario.lng,
          valorEmpresa: pedido.tipoEntrega.valorEmpresa,
          valorEntregador: pedido.tipoEntrega.valorEntregador,
          valorVip: pedido.tipoEntrega.valorVip,
          pedido: {
            id: _pedido.id,
            valorTotal: _pedido.valorTotal
          }
        }

        const corrida = {
          entregas: [entrega],
          loja: this.loja,
          status: "Espera",
          vipDelivery: true,
        }

        const res = (await this.$api.post("/corridas", corrida))?.data;

        if (!res.success) {
          erroFn("Erro ao Chamar o Entregador! Código: PPMCC. " + res.message, res, self);
          return;
        }

        pedido.entrega = {
          data: moment().tz("America/Sao_Paulo").toDate(),
          corrida: res.data.id
        }


      }

      const ret = await this.$api.put('/pedidos/' + pedido.id, pedido);

      if (!ret.data.success) {
        erroFn("Erro ao Aceitar o Pedido! Código: PPMCC. " + ret.data.message, ret.data, self);
        return;
      }
      self.$toast.add({
        severity: "success",
        summary: "Sucesso!",
        detail: "Pedido Aceito!",
        life: 3000,
      });

      self.pedido_edit_visible = false;

      self.acompanhar_entrega(pedido);
      self.monitorar(pedido);



      this.loading--;

    },

    pedido_recusar(_pedido, event) {

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function (message, error, context) {

        console.log("ERRO RECUSA PEDIDO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja RECUSAR este Pedido?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {

          self.loading++;

          pedido.status = "Recusado";
          pedido.dataRecusado = moment().tz("America/Sao_Paulo").toDate();

          const ret = await this.$api.put("/pedidos/" + pedido.id, { status: "Recusado", dataRecusado: moment().tz("America/Sao_Paulo").toDate() });

          if (ret.data.success) {

            if (false == true && Array.isArray(pedido.retornoGerencia) && pedido.retornoGerencia.length > 0) {

              try {

                const dataPost = {
                  "body": {
                    "custom_id": pedido.id,
                    "notification_url": "https://api.vipapp.mm.tec.br/delivery/notificacao"
                  },
                  "params": {
                    "id": pedido.retornoGerencia[0].identifiers['charge_id']
                  }
                };

                const response = await this.$apiv1.post("/cancelar", dataPost);
                const data = response.data;

                if (data.success) {

                  self.$toast.add({
                    severity: "success",
                    summary: "Sucesso!",
                    detail: "Pedido Recusado!",
                    life: 3000,
                  });

                  self.pedido_edit_visible = false;

                } else {
                  erroFn("Erro ao Recusar o Pedido! Código: PPCP. Verifique o LOG.", data, self);
                }

              } catch (error) {
                erroFn("Erro ao Recusar o Pedido! Código: PPCP. Verifique o LOG.", error, self);
              }

            } else {

              self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Pedido Recusado!",
                life: 3000,
              });

              self.pedido_edit_visible = false;

            }

          } else {
            erroFn("Erro ao Recusar o Pedido! Código: PFLS. Verifique o LOG.", ret.data, self);
          }

          self.loading--;

        }

      });

    },

    pedido_imprimir(pedido) {

      window.open(
        "/" + this.$router.resolve({
          name: 'impressao',
          params: {
            id: pedido.id,
          },
        }).href
      );

    },

    async monitorar(pedido) {



      if (!pedido?.entrega) return;

      try {
        const response = await this.$api.get('/corridas/' + pedido.entrega.corrida);
        console.log("Está monitorando a corrida 1", response.data.data.id)
        if (response.data.success) {

          if (response.data.data.status == "Finalizada") return;

          console.log("Está monitorando a corrida 2", response.data.data.id)

          const data = {
            "success": response.data.success,
            "status": response.data.data.status,
            "entregador": response.data.data.entregador,
            "dados": response.data.data,
            "data": moment().tz("America/Sao_Paulo").toDate()
          }
          if (data.status == "Iniciada") {
            pedido.status = "Entregando";
            const prevStatus = this.getStatusOfPedido(pedido.id);

            if (prevStatus != "" && prevStatus != pedido.status) {
              delete this.pedidos[prevStatus][pedido.id];
            }

            if (typeof this.pedidos[pedido.status] != "undefined") {
              this.pedidos[pedido.status][pedido.id] = pedido;
            }
          }

          let statusMega = data.status;
          console.log("Está monitorando a corrida 3", response.data.data.id)
          if (typeof this.pedidos[pedido.status][pedido.id] == "undefined") return;

          this.pedidos[pedido.status][pedido.id].statusEntrega = {
            status: statusMega,
            entregador: data.entregador,
            data: data.data
          };

          console.log("Está monitorando a corrida 4", response.data.data.id)

        } else {
          return;
        }

      } catch (e) {

        //console.log("!! ERRO NO MONITORAMENTO: PEDIDO " + pedido + " !!");
        console.log(e);

      }

      await new Promise(r => setTimeout(r, 2000));

      this.monitorar(pedido);

    },

    acompanhar_entrega(pedido) {

      const self = this;
      console.log("acompanhar entrega", pedido.statusEntrega)

      if (typeof this.mapMarkers.destino != "undefined")
        delete this.mapMarkers.destino;

      if (typeof this.mapMarkers.entregador != "undefined")
        delete this.mapMarkers.entregador;

      this.mapMarkers.destino = {
        "title": pedido.usuario.nome,
        "position": {
          "lat": pedido.usuario.lat,
          "lng": pedido.usuario.lng
        },
        "icon": "/icos/destinocorrida.png"
      };

      if (typeof pedido.statusEntrega != "undefined" && pedido.statusEntrega.entregador != null) {

        this.mapMarkers.entregador = {
          "title": pedido.statusEntrega.entregador.nome,
          "position": {
            "lat": 0,
            "lng": 0
          },
          "icon": "/icos/iconemegawebmotoqueiro.png"
        };

        this.$rt.on("Usuario.update." + pedido.statusEntrega.entregador.id, (dados) => {
          let entregador = self.$utils.getStdObject(self.mapMarkers.entregador);
          const pos = dados.data.data;
          //console.log(pos)
          entregador.position = {
            lat: pos.lat,
            lng: pos.lng
          };

          self.mapMarkers.entregador = entregador;

          self.centralizar_mapa();
        });

        // refEntregador = firebase.database().ref('Empresas/' + this.loja.logistica + "/LocalMotoboy/" + pedido.statusEntrega.entregador.id + "/l");
        // refEntregador.on("value", function (snapshot) {
        //   const pos = snapshot.val();

        //   let entregador = self.$utils.getStdObject(self.mapMarkers.entregador);

        //   entregador.position = {
        //     lat: pos[0],
        //     lng: pos[1]
        //   };

        //   self.mapMarkers.entregador = entregador;

        //   self.centralizar_mapa();

        // });

      }

    },

    async pedido_pronto(_pedido) {

      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function (message, error, context) {

        console.log("ERRO MARCAR PEDIDO COMO PRONTO:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Pronto";
      pedido.dataPronto = moment().tz("America/Sao_Paulo").toDate();

      const ret = await this.$api.put('/pedidos/' + pedido.id, { status: "Pronto", dataPronto: moment().tz("America/Sao_Paulo").toDate() });

      if (ret.data.success) {

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Pedido marcado como Pronto!",
          life: 3000,
        });

        self.pedido_edit_visible = false;

      } else {
        erroFn("Erro ao marcar o Pedido como Pronto! Código: PMLS. Verifique o LOG.", ret.data, self);
      }

      this.loading--;

    },

    async pedido_entregar(_pedido) {

      this.loading++;

      const self = this;
      let pedido = this.$utils.getStdObject(_pedido);

      const erroFn = function (message, error, context) {

        console.log("ERRO MARCAR PEDIDO COMO ENTREGUE:");
        console.log(error);

        context.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: message,
          life: 5000,
        });

      };

      pedido.status = "Finalizado";
      pedido.dataFinal = moment().tz("America/Sao_Paulo").toDate();

      const ret = this.$api.put("/pedidos/" + _pedido.id, { status: "Finalizado", dataFinal: moment().tz("America/Sao_Paulo").toDate() })
      console.log(ret.data)

      if (ret.data.success) {

        self.$toast.add({
          severity: "success",
          summary: "Sucesso!",
          detail: "Pedido marcado como Entregue!",
          life: 3000,
        });

        self.pedido_edit_visible = false;


      } else {

        erroFn("Erro ao marcar o Pedido como Entregue! Código: PELS. Verifique o LOG.", ret.data, self);

      }

      this.loading--;

    },

    centralizar_mapa() {

      let bounds = new this.$refs.mapa.api.LatLngBounds();
      for (const m of Object.values(this.mapMarkers)) {
        bounds.extend(m.position);
      }

      this.$refs.mapa.map.fitBounds(bounds);
      this.$refs.mapa.map.panToBounds(bounds);

    }

  },
  components: {
    GoogleMap,
    Marker,
    PedidoView
  },

};
</script>
<style scoped>
:deep(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link) {
  background: #c82a03;
  color: white;
  border-color: #c82a03;
}

:deep(.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link) {
  background: #c82a03;
  border-color: #c82a03;
  color: #ffffff;
}

:deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  background: #cf6a51;
  color: white;
}

:deep(.p-accordion .p-accordion-content),
:deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  border-color: #c82a03;
}

:deep(#pedidos li:last-child) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: 0;
}

:deep(.pedido) {
  cursor: pointer;
}
</style>